@import "./mixin.scss";


// Font Var
$MontserratFont: 'Montserrat', sans-serif;
$SpartanFont: 'Spartan', sans-serif;

// Color Var
$primaryColor: #2952E3;
$dangerColor: #F10;
$blackColor: #000000;
$whiteColor: #ffffff;
$GrayColor: #9AA4C7;
$GrayTwoColor: #A5A5A5;
$DarkBlack: #0F0E13;
$DarkBlackTwo: #343142;
$DarkBlackThree: #222028;
$DarkBlackFour: #131218;
$DarkBlackFive: #1F1F1F;
$DarkBlackSix: #202020;
$DarkBlackSeven: #060606;
$DarkBlackEight: #0A0A0A;
  

// ********** media query  variables ********** 
$LaptopL: "only screen and (max-width : 1366px)";
$Laptop: "only screen and (max-width : 1199px)";
$Ipad: "only screen and (max-width : 1024px)";
$IpadS: "only screen and (max-width: 991px)";
$PhoneL: "only screen and (max-width: 767px)";
$PhoneS: "only screen and (max-width: 575px)";

// bootstrap theme customize
$body-bg: $blackColor;
$body-color: $whiteColor;
$font-family-sans-serif: $MontserratFont; 
$container-max-widths: (sm: rem(1270),) !default;
$grid-gutter-width: 30px;
$theme-colors: (
  "primary": $primaryColor,
  "danger": $dangerColor, 
  "secondary":  $whiteColor,
);
$btn-padding-y:  14px;
$btn-padding-x:  18px;
$btn-border-radius: rem(40);
$btn-font-weight: 700;
$btn-font-size:rem(14);
$btn-line-height: rem(14);
$badge-border-radius: rem(4);
$badge-padding-y: rem(5);
$badge-padding-x: rem(8);
$badge-font-size: rem(14);
$badge-font-weight: 600;
$headings-margin-bottom: rem(20);
$headings-font-family: $SpartanFont;
$headings-font-weight: 700;
$h1-font-size: rem(46);
$h2-font-size: rem(36);
$h3-font-size: rem(20);
$h4-font-size: rem(16);
$gray-600: $GrayColor;
$spacers: (0: 0,
  1: rem(10),
  2: rem(15),
  3: rem(20), 
  4: rem(30),
  5: rem(35),
  6: rem(40),
) !default; 
$gutters: (
  1: rem(20),
  2: rem(30),
  3: rem(40),
  );
$input-placeholder-color: $whiteColor;
$input-border-color: $blackColor;
$input-padding-y: 22px;
$input-padding-x: 23px;
$input-border-radius: rem(10);
$input-font-size: 14px;
$input-line-height: 1.1;
$input-font-weight: 500;
$input-font-family: $SpartanFont; 
$modal-content-bg: $DarkBlack;
$modal-backdrop-opacity: 0.66;
$modal-content-border-radius:rem(20);
$modal-md: 1240px;

