
/** Use For PX to Rem Convter **/
@function rem($pixels, $context: 16) {
  @return #{$pixels/$context}rem;
}

//mixin size 
@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;
  @if $radius != 0 {
    border-radius: $radius;
  }
}
//image mixin
@mixin img($type) {
  object-fit: $type;
  height: 100%;
  width: 100%;
}

 // device specific hide show content mixin
 @mixin hide-for($device, $showsm) {
  @if $showsm {
    display: none;
    @media #{$device}{
      display: flex;
    }
  } @else {
    display: flex;
    @media #{$device}{
      display: none;
    }
  }
}

//Flex width mixin start
@mixin width($size: null, $calc: null) {
  @if $size == 'auto' {
    flex: 1 1 $size;
    max-width: calc(100% - #{$calc});
    width: calc(100% - #{$calc});
  } @else {
  flex: 0 0 $size;
  max-width: $size;
  width: $size;
  }
}
