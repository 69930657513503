/* Font family */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './assets/css/variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 1);
}
.page-wrapper {
  position: relative;
  padding-top: rem(160);
  overflow: hidden;
  @media #{$IpadS} {
    padding-top: rem(90);
  }
}
.effact-circle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 100%;
  &.bottom-right {
    top: inherit;
    left: inherit;
    right: 0;
    bottom: 0;
  }
}
img {
  max-width: 100%;
}
.btn {
  &-secondary {
    color: $primaryColor;
    &:hover { 
      color: $whiteColor;
      background-color: transparent;
    }
  }
}
.text-18 {
  font-size: rem(18);
  line-height: rem(30);
  @media #{$PhoneL} {
    font-size: rem(16);
    line-height: rem(26);
  }
}
.text-16 {
  font-size: rem(16);
  line-height: rem(26);
  @media #{$PhoneL} {
    font-size: rem(15);
    line-height: rem(25);
  }
}
.text-14 {
  font-size: rem(14);
  line-height: rem(16);
}

h1 {
  line-height: rem(60);
  @media #{$Laptop} {
    font-size: rem(40);
    line-height: rem(54);
  }
  @media #{$IpadS} {
    font-size: rem(36);
    line-height: rem(50);
  }
  @media #{$PhoneL} {
    font-size: rem(32);
    line-height: rem(44);
  }
}
h2 {
  line-height: rem(50);
  @media #{$Laptop} {
    font-size: rem(30);
    line-height: rem(44);
  }
  @media #{$IpadS} {
    font-size: rem(28);
    line-height: rem(42);
  }
  @media #{$PhoneL} {
    font-size: rem(26);
    line-height: rem(40);
  }
}
h3 {
  line-height: rem(22);
  @media #{$Laptop} {
    font-size: rem(18);
    line-height: rem(20);
  }
}

.badge {
  &.badge-sm {
    padding: rem(3) rem(5);
    font-size: rem(8);
    line-height: rem(11);
  }
}

.team-modal {
  padding: rem(30);
  @media #{$PhoneL} {
    padding: rem(15);
  }
  &.d-block {
    display: flex !important;
  }
  .modal-dialog {
    margin: auto;
  }
  .modal-content {
    position: relative;
    .close-btn {
      position: absolute;
      top: rem(58);
      right: rem(48);
      border: 0px;
      background-color: transparent;
      padding: 0;
      z-index: 99;
      outline: 0;
      height: rem(16);
      width: rem(16);
      @media #{$PhoneL} {
        top: rem(15);
        right: rem(15);
      }
    }
  }
  .modal-body {
    padding: rem(60) rem(50) rem(50);
    @media #{$PhoneL} {
      padding: rem(30) rem(15);
    }
    .user-profile {
      display: flex;
      align-items: center;
      margin-bottom: rem(30);
      @media #{$PhoneL} {
        padding-right: rem(26);
      }
      .user-thumb {
        @include square(rem(100), 50%);
        flex: 0 0 rem(100);
        @media #{$PhoneL} {
          @include square(rem(60), 50%);
          flex: 0 0 rem(60);
        }
        img {
          @include img(cover);
        }
      }
      .user-details {
        padding-left: rem(24);
        @media #{$PhoneL} {
          padding-left: rem(15);
        }
        .designation {
          font-weight: 500;
          font-family: $SpartanFont;
          margin: 0 0 rem(17);
        }
        .social-list {
          display: flex;
          align-items: center;
          a {
            margin-right: rem(14);
            display: flex;
            transition: all 0.3s ease-in-out;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .user-content {
      p {
        margin-bottom: rem(20);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
